export const SUPPORTED_LOCALES = [
  {
    code: 'en',
    base: '',
    flag: 'us',
    name: 'EN',
    translations: '/translations/en.json',
    iso: 'en-US'
  },
  {
    code: 'fr',
    base: '/fr',
    flag: 'fr',
    name: 'FR',
    translations: '/translations/fr.json',
    iso: 'fr-FR'
  },
  {
    code: 'de',
    base: '/de',
    flag: 'de',
    name: 'DE',
    translations: '/translations/de.json',
    iso: 'de-DE'
  },
  {
    code: 'es',
    base: '/es',
    flag: 'es',
    name: 'ES',
    translations: '/translations/es.json',
    iso: 'es-ES'
  },
  {
    code: 'it',
    base: '/it',
    flag: 'it',
    name: 'IT',
    translations: '/translations/it.json',
    iso: 'it-IT'
  },
  {
    code: 'sl',
    base: '/sl',
    flag: 'sl',
    name: 'SL',
    translations: '/translations/sl.json',
    iso: 'sl-SI'
  }
]
