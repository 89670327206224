<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="700"
    content-class="elevation-0"
  >
    <v-container>
      <v-btn block text depressed color="white" @click="closeDialog">
        <span class="text-decoration-underline">{{ $t('close') }}</span>
      </v-btn>
      <div class="white flex-grow-1 rounded pt-5 px-5 mb-5">
        <ShareLink />
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import ShareLink from '@/components/ShareLink.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SharePopup',
  components: {
    ShareLink
  },
  computed: {
    ...mapGetters([
      'isShareDialogVisible'
    ])
  },
  data: function () {
    return {
      dialogVisible: true
    }
  },
  watch: {
    dialogVisible (visible) {
      if (visible) {
        this.$store.commit('SHOW_SHARE_DIALOG')
      } else {
        this.closeDialog()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$store.commit('CLOSE_SHARE_DIALOG')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
