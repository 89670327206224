<template>
  <v-dialog
    v-model="dialogVisible"
    content-class="elevation-0"
    width="1000"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <v-container class="text-center" data-cy-start-notice-dialog>
      <div class="flex-grow-1 white rounded pa-5 mb-5">
        <div class="display-1 accent--text mb-3">
          {{ $t('dialogStart.title') }}
        </div>
        <div>
          {{ $t('dialogStart.subtitlePart1') }} <strong>{{ $t('dialogStart.subtitlePart2') }}</strong>.
          <br />
          {{ $t('dialogStart.subtitlePart3') }} <strong>{{ $t('dialogStart.subtitlePart4') }}</strong> {{ $t('dialogStart.subtitlePart5') }} <strong>{{ $t('dialogStart.subtitlePart6') }}</strong> {{ $t('dialogStart.subtitlePart7') }}
        </div>
      </div>

      <div class="flex-grow-1">
        <v-row>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/mtablet.png')" alt="MESI mTABLET" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                {{ $t('dialogStart.card1Title') }}
              </v-card-title>
              <v-card-text>
                {{ $t('dialogStart.card1DescriptionPart1') }} <strong>{{ $t('dialogStart.card1DescriptionPart2') }}</strong>, {{ $t('dialogStart.card1DescriptionPart3') }}, <strong>{{ $t('dialogStart.card1DescriptionPart4') }}</strong>.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/modules-apps.png')" alt="Modules and apps" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                {{ $t('dialogStart.card2Title') }}
              </v-card-title>
              <v-card-text>
                {{ $t('dialogStart.card2DescriptionPart1') }} <strong>{{ $t('dialogStart.card2DescriptionPart2') }}</strong> {{ $t('dialogStart.card2DescriptionPart3') }} <storng>{{ $t('dialogStart.card2DescriptionPart4') }}</storng>.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/mrecords.png')" alt="MESI mRECORDS" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                {{ $t('dialogStart.card3Title') }}
              </v-card-title>
              <v-card-text>
                {{ $t('dialogStart.card3DescriptionPart1') }} <strong>{{ $t('dialogStart.card3DescriptionPart2') }}</strong>. {{ $t('dialogStart.card3DescriptionPart3') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-btn color="accent" block x-large rounded depressed class="subtitle-1 mt-5" @click="closeDialog" data-cy-start-cta>{{ $t('dialogStart.btn') }}</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialogVisible: true
    }
  },
  created () {
    if (this.$router.currentRoute.name === 'Code') {
      this.dialogVisible = false
    } else {
      this.dialogVisible = true
    }
  },
  watch: {
    // when clicked outside of dialog, we close it as well
    // (need localStorage written now too)
    dialogVisible (val) {
      if (!val && this.$router.currentRoute.name !== 'Code') {
        this.closeDialog()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$localStorage.set('mConf_startNoticePopupClosed', true)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: 100px;
}
</style>
