<template>
  <div class="visual-block-step step-4-visual-block pa-6 mt-8 mb-12" :class="{ 'active-step' : isActiveStep}" ref="Summary">
    <CurrentSelectionListVisual />
  </div>
</template>

<script>
import CurrentSelectionListVisual from '@/components/visualSteps/partials/CurrentSelectionListVisual.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SummaryVisual',
  props: {
    stepIndex: Number,
    selected: Array,
    systemData: Object,
    isActiveStep: Boolean
  },
  components: {
    CurrentSelectionListVisual
  },
  computed: {
    ...mapGetters([
      'steps'
    ]),
    componentName () {
      return this.steps[this.stepIndex].componentName
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  }
}
</script>
