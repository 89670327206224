<template>
  <div class="price-wrap text-center">
    <div class="price-div display-2">
      <span class="display-1 font-weight-light">{{ $t('yourConfig') }}</span>
      <!--v-tooltip top max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn fab depressed icon v-on="on" class="d-inline-block ml-3" x-small>
            <v-icon color="grey lighten-1" large>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Your chosen configuration with the featured price is available in the 24-month MESI Light package. More information about individual monthly packages, pricing details and terms and conditions is available in the following steps.</span>
      </v-tooltip-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceDisplay'
}
</script>
