<template>
  <div>
      <div class="font-weight-light text--secondary body-2 pt-4 pb-1">
      <p>
        {{ $t('disclamerPart1') }} <span class="font-weight-regular">{{ $t('disclamerPart2') }}</span>
        {{ $t('disclamerPart3') }} <router-link target="_blank" to="terms-conditions" class="font-weight-regular">{{ $t('here') }}.</router-link> {{ $t('disclamerPart4') }} <router-link target="_blank" to="#TODO" class="font-weight-regular">{{ $t('here') }}.</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer'
}

</script>

<style lang="scss" scoped>
</style>
