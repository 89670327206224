<template>
  <v-dialog
    v-model="dialogVisible"
    content-class="elevation-0"
    width="500"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container class="text-center">
      <v-btn v-if="!genericDialogLoading" block text depressed color="white" @click="closeDialog">
        <span class="text-decoration-underline">Close</span>
      </v-btn>
      <div class="flex-grow-1 white rounded px-5 py-9 mb-5">
        <div class="d-flex justify-center">
          <v-img :src="src" alt="MESI Heart" aspect-ratio="1" contain width="100" class="flex-grow-0 mb-4" />
        </div>
        <div v-html="genericDialogContent"></div>
        <div v-if="genericDialogLoading" class="mt-3">
          <v-progress-circular
            indeterminate
            color="accent"
          ></v-progress-circular>
        </div>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GenericDialog',
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg'),
      dialogVisible: true
    }
  },
  watch: {
    dialogVisible (visible) {
      if (!visible) {
        this.closeDialog()
      }
    }
  },
  computed: {
    ...mapGetters([
      'genericDialogContent',
      'genericDialogLoading'
    ])
  },
  methods: {
    closeDialog () {
      this.$store.commit('CLOSE_GENERIC_DIALOG')
    }
  }
}
</script>
