<template>
  <div class="share font-weight-light py-8" :class="{ 'd-none' : hide }">
    <div class="mb-3">{{ $t('linkShareDescription') }}</div>
    <div class="share-input-wrap">
      <v-text-field
        :value="configURL"
        depressed
        rounded
        readonly
        filled
        dense
        hide-details
        class="copy-txt-field"
        @keypress.enter="copy"
      >
      </v-text-field>
      <v-btn
        depressed
        outlined
        absolute
        rounded
        height="100%"
        @click="copyToClipboard"
        :class="copiedSuccessClass"
        class="copy-btn ma-0">
        {{ copyText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShareLink',
  data: function () {
    return {
      copyTextDefault: 'COPY',
      copyText: 'COPY',
      copiedSuccessClass: 'accent--text white',
      hide: false
    }
  },
  computed: {
    ...mapGetters([
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'configCode',
      'configURL'
    ])
  },
  created () {
    this.copyToClipboard = this.debounce(this.copyToClipboard)
  },
  mounted () {
    this.copyText = this.$t('copyTextDefault')
    this.copyTextDefault = this.$t('copyTextDefault')
  },
  methods: {
    copyToClipboard () {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', e => e.stopPropagation())
      el.value = this.configURL
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.clipboardSuccessHandler()
    },
    clipboardSuccessHandler () {
      this.copiedSuccessClass = 'white--text accent'
      this.copyText = this.$t('copiedText')
      if (this.saEvent) {
        this.saEvent('CopiedShareLink')
      }
      this.trackEvent('CopiedShareLink', 'ButtonClick')
      const tm = setTimeout(() => {
        this.copyText = this.copyTextDefault
        this.copiedSuccessClass = 'accent--text white'
        clearTimeout(tm)
      }, 2000)
    },
    clipboardErrorHandler () {
      this.copiedSuccessClass = 'red--text white'
      this.copyText = 'error'
      const tm = setTimeout(() => {
        this.copyText = this.copyTextDefault
        this.copiedSuccessClass = 'accent--text white'
        clearTimeout(tm)
      }, 2000)
    }
  }
}

</script>

<style lang="scss" scoped>
.share-input-wrap {
  position: relative;
}

.copy-btn {
  top: 0;
  right: 0;
  border: 3px solid var(--v-accent-base) !important;
}
</style>
