<template>
  <div class="visual-block-step step-1-visual-block ma-6" :class="{ 'active-step' : isActiveStep }" ref="ExtModules">
    <div class="text-center" v-if="!allImagesLoaded">
      <v-img :src="imgPlaceholder" alt="MESI mTABLET" rel="preload" :lazy-src="imgPlaceholder" width="100%">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate color="accent"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-img
      class="module-img"
      :alt="img.name"
      :class="imgClass(img)"
      v-for="(img,index) in imgsData"
      :src="img.src"
      @load="imgLoaded"
      width="100%"
      :v-if="allImagesLoaded"
      :key="index">
    </v-img>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExtModulesVisual',
  props: {
    stepIndex: Number,
    systemData: Object,
    fieldOfCareData: Array,
    isActiveStep: Boolean
  },
  data: function () {
    return {
      pngNames: ['charging-plate', 'abi', 'bp-yellow', 'bp-red', 'ecg', 'tbi-no-cuffs', 'mtablet', 'tbi-cuffs', 'spo2', 'spiro'],
      atLeastOneImageLoaded: false,
      numberOfImagesLoaded: 0,
      lazyImgSrc: this.imgPlaceholder
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  },
  computed: {
    ...mapGetters({
      selected: 'selectedExtensionsModules'
    }),
    ...mapGetters([
      'activeStep',
      'steps'
    ]),
    componentName () {
      return this.steps[this.stepIndex].componentName
    },
    imgPlaceholder () {
      return require('@/assets/extModules/visual/mtablet.png')
    },
    systemDataOk () {
      return typeof this.systemData !== 'undefined'
    },
    imgsData () {
      const l = this.pngNames.map(name => ({ name: name, src: require('@/assets/extModules/visual/' + name + '.png') }))
      return l
    },
    imagesToDisplay () {
      const ar = []
      for (const extModID of this.selected) {
        const imgNames = this.extensionModuleObj(extModID).visualImgs
        ar.push(...imgNames)
      }
      return this.arrayRemoveDuplicates(ar)
    },
    systemVisualImgs () {
      if (!this.systemDataOk) {
        return []
      }
      return this.systemData.visualImgs
    },
    allImagesLoaded () {
      return this.numberOfImagesLoaded === this.pngNames.length
    }
  },
  methods: {
    imgLoaded () {
      this.numberOfImagesLoaded++
      if (!this.atLeastOneImageLoaded) {
        this.atLeastOneImageLoaded = true
        /* this.$nextTick(function () {
          console.warn('imgLoaded() call adjustLine')
          this.adjustLine()
        }) */
      }
    },
    extensionModuleObj (id) {
      return this.$store.getters.extModByID(id)
    },
    imgClass (imgObj) {
      if (!this.systemDataOk && !this.selected) {
        return ''
      }
      if (imgObj.name === 'mtablet') {
        return 'active ' + imgObj.name
      }

      return this.imagesToDisplay.includes(imgObj.name) ? 'active ' + imgObj.name : imgObj.name
    }
  }
}
</script>

<style scoped lang="scss">
  .visual-block-step {
    position: relative;
  }

  .module-img {
    opacity: 0.15;

    &.bp-yellow, .bp-red {
      opacity: 0;
    }

    &:not(:first-child) {
      position: absolute !important;
      top: 0;
      left: 0;
    }

    &.active {
      opacity: 1;
    }
  }
</style>
