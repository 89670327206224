<template>
  <v-dialog
    v-model="showDialog"
    content-class="elevation-0"
    width="700"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container class="text-center">
      <div class="flex-grow-1 white rounded px-5 py-9 mb-5">
        <img :src="src" alt="MESI heart" width="150" />
        <div class="my-5" :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-3': $vuetify.breakpoint.mdAndUp}"><strong>{{ $t('dialogThankYou.text1') }}</strong> {{ $t('dialogThankYou.text2') }}</div>
        <div class="headline">{{ $t('dialogThankYou.text3') }}</div>
      </div>
      <v-btn color="accent" block x-large rounded depressed class="subtitle-1" @click="closeDialogs">{{ $t('dialogThankYou.btn') }}</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  computed: {
    showDialog () {
      return this.show
    }
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  methods: {
    closeDialogs () {
      this.$store.commit('CLOSE_ALL_DIALOGS')
    }
  }
}
</script>
