<template>
  <v-stepper
    :alt-labels="$vuetify.breakpoint.mdAndUp"
    non-linear
    v-model="activeStepFrom1"
    @change="stepChangedManually"
    class="transparent elevation-0"
    :class="{ 'large-screens': $vuetify.breakpoint.mdAndUp }"
    :style="stepperStyle">
    <v-stepper-header>
      <template v-for="(step,k) in steps">
        <v-stepper-step
        :key="$t(step.name)"
        :step="k+1"
        :complete="step.complete"
        :editable="stepsEditable"
        :data-cy="'step-'+(k+1)"
        color="accent">{{ $t(step.name) }}</v-stepper-step>
        <v-divider :key="k" v-if="k<(steps.length-1)"></v-divider>
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        v-for="(step,k) in steps"
        :key="`${k}-content`"
        :step="k+1"
      >
        <component v-bind:is="stepComponentName(k)" :systemData="systemData" :fieldOfCareData="fieldOfCareData"></component>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ExtModules from '@/components/interactiveSteps/ExtModules.vue'
import Apps from '@/components/interactiveSteps/Apps.vue'
import Layout from '@/components/interactiveSteps/Layout.vue'
import Accessories from '@/components/interactiveSteps/Accessories.vue'
import Summary from '@/components/interactiveSteps/Summary.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Stepper',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    ExtModules, Apps, Layout, Accessories, Summary
  },
  computed: {
    ...mapGetters([
      'activeStep',
      'isLastStep',
      'steps',
      'appHeight',
      'navigationHeight',
      'selectedExtensionsModules'
    ]),
    itemsForCodeUrl () {
      return {
        selected: this.$store.state.selected,
        preselected: this.$store.state.preselected
      }
    },
    stepsEditable () {
      return this.selectedExtensionsModules.length > 0
    },
    activeStepFrom1: {
      get () {
        return this.$store.getters.activeStep + 1
      },
      set (newVal) {
        this.$store.commit('CHANGE_STEP', newVal - 1)
      }
    },
    stepperStyle () {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        return {}
      }

      const stepperHeight = this.appHeight - this.navigationHeight

      return {
        height: stepperHeight + 'px'
      }
    }
  },
  watch: {
    activeStep (to, from) {
      if (to >= (this.steps.length - 1)) {
        this.updateShareUrl()
      }
    }
  },
  created () {
    if (this.isLastStep) {
      this.updateShareUrl()
    }
  },
  methods: {
    stepComponentName (indx) {
      return this.steps[indx].componentName
    },
    stepChangedManually (num) {
    },
    getShareURL (code) {
      const url = window.location.origin
      if (this.$i18n.locale !== 'en') {
        return url + '/' + this.$i18n.locale + '/code/' + code
      } else {
        return url + '/code/' + code
      }
    },
    updateShareUrl () {
      const configCode = this.encodeDataForURL(this.itemsForCodeUrl)
      this.$store.commit('SET_CONFIG_CODE', configCode)

      const shareUrl = this.getShareURL(configCode)
      this.$store.commit('SET_CONFIG_URL', shareUrl)
    }
  }
}
</script>

<style lang="scss">
html {
  overflow-y: auto; // vuetify has overflow: scroll; set on html - override it here
}

.v-stepper__label {
  text-align: center !important;
}

.v-stepper__step--complete .v-stepper__label {
  color: var(--v-primary-base) !important;
}

.v-stepper__step--active .v-stepper__label {
  text-shadow: none !important;
  color: var(--v-primary-base) !important;
}

.v-stepper {
  &.large-screens {
    max-height: 100vh;
    // height: calc(100% - 130px);
    // overflow: hidden scroll;
    display: flex;
    flex-direction: column;
  }
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-stepper__items {
  overflow: hidden scroll !important;
}

.v-btn.c-mesi-btn {
  background: #fff;
  margin-bottom: 8px;
  border: 3px solid var(--v-accent-base);

  &:not(:last-child) {
    margin-right: 8px;
  }

  .v-btn__content {
    letter-spacing: 0.6px;
  }

  &:not(.selected) {
    .v-btn__content {
      color: var(--v-secondary-base);
    }
  }
}
</style>
