<template>
  <div>
    <div class="mb-10">
      <div class="d-lg-flex align-top">
        <div>
          <span class="display-1 text-uppercase accent--text">{{ $t('yourConfigComplete') }}</span>
        </div>
        <div class="text-right pl-lg-5 mt-5 mt-lg-0 align-self-end d-flex flex-grow-1">
          <v-btn depressed outlined rounded class="mx-1 mb-2" @click="editConfig">{{ $t('edit') }}</v-btn>
          <v-btn depressed outlined rounded class="mx-1" @click="openShareDialog">{{ $t('share') }}</v-btn>
        </div>
      </div>
      <div class="py-5">
        {{ $t('summaryDescription') }}
      </div>
    </div>
    <div class="my-5">
      <InquiryActions />
    </div>
    <div class="caption">
    </div>
    <ShareDialog v-if="isShareDialogVisible" />
  </div>
</template>

<script>
import InquiryActions from '@/components/interactiveSteps/partials/InquiryActions.vue'
import ShareDialog from '@/components/dialogs/Share.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Summary',
  props: {
    fieldOfCareData: Array
  },
  components: {
    InquiryActions, ShareDialog
  },
  computed: {
    ...mapGetters([
      'isShareDialogVisible'
    ])
  },
  methods: {
    editConfig () {
      if (this.saEvent) {
        this.saEvent('edit-btn')
      }
      this.trackEvent('Edit', 'ButtonClick')
      this.$store.commit('CHANGE_STEP', 0)
    },
    openShareDialog () {
      if (this.saEvent) {
        this.saEvent('share-btn')
      }
      this.trackEvent('Share', 'ButtonClick')
      this.$store.commit('SHOW_SHARE_DIALOG')
    }
  }
}
</script>
