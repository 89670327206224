<template>
  <div class="systems-wrap mb-10 text-center">
    <sectionTitle>
      <span class="cy-fow">Choose your language</span>
    </sectionTitle>
    <div>
      <v-btn depressed rounded block large
        v-for="(item,k) in languages"
        :key="k"
        @click="selectLanguage(item.id)"
        class="c-mesi-btn"
      >
        {{ item.name }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'

export default {
  name: 'PreSelect',
  components: {
    sectionTitle
  },
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  data: function () {
    return {
      languages: [
        {
          name: 'English',
          nameShort: 'EN',
          id: 'en'
        },
        {
          name: 'Français',
          nameShort: 'FR',
          id: 'fr'
        },
        {
          name: 'Deutsch',
          nameShort: 'DE',
          id: 'de'
        },
        {
          name: 'Italiano',
          nameShort: 'IT',
          id: 'it'
        },
        {
          name: 'Slovenščina',
          nameShort: 'SI',
          id: 'sl'
        },
        {
          name: 'Español',
          nameShort: 'ES',
          id: 'es'
        }
      ]
    }
  },
  methods: {
    selectLanguage (lang) {
      this.$emit('language', lang)
      /* if (lang !== 'en') {
        this.$router.push(`/${lang}/`)
      } */
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
