<template>
  <div>
    <v-row>
      <v-col cols="6" v-for="extModID in alwaysSelectedExtMods" :key="extModID">
        <strong>{{ extModByID(extModID).name }}</strong>
        <ul>
          <li
            v-for="item in includesList(extModID)"
            :key="item">
            {{ item }}
          </li>
        </ul>
        <ul>
          <li
            v-for="id in extModByID(extModID).applications.included"
            :key="id">
            {{ applicationByID(id).name }} {{ $t('app') }}
          </li>
          <li
            v-for="id in extModByID(extModID).accessories.included"
            :key="id">
            {{ accessoryByID(id).name }}
          </li>
        </ul>
      </v-col>

      <v-col cols="6" v-for="extModID in selectedExtensionsModulesModified" :key="extModID">
        <strong data-testid="extModName">{{ extModByID(extModID).name }}</strong>
        <ul>
          <li
            v-for="item in includesList(extModID)"
            :key="item">
            {{ item }}
          </li>
        </ul>
        <ul>
          <li
            v-for="id in extModByID(extModID).applications.included"
            :key="id">
            {{ applicationByID(id).name }} {{ $t('app') }}
          </li>
          <li
            v-for="id in extModByID(extModID).accessories.included"
            :key="id">
            {{ accessoryByID(id).name }}
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" v-if="additionalApps.length > 0">
        <strong>Additional applications</strong>
        <ul>
          <li
            v-for="id in additionalApps"
            :key="id">
            {{ applicationByID(id).name }} {{ $t('app') }}
          </li>
        </ul>
      </v-col>
      <v-col cols="6" v-if="additionalAccessories.length > 0">
        <strong>Additional accessories</strong>
        <div class>
          <ul>
            <li
              v-for="id in additionalAccessories"
              :key="id">
              {{ accessoryByID(id).name }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <strong>{{ $t('layout') }}: </strong> {{ layoutByID(selectedLayout).name }}
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentSelectionList',
  props: {
    fieldOfCareData: Array
  },
  components: {
  },
  computed: {
    ...mapGetters([
      'extModByID',
      'applicationByID',
      'layoutByID',
      'accessoryByID',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedLayout',
      'preselectedApplications',
      'selectedAccessories',
      'preselectedAccessories'
    ]),
    selectedExtensionsModulesModified () {
      if (this.selectedExtensionsModules.includes('TBISYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
        // remove TBISYSCS4
        // add TBPSYSCS4
        const modifiedSelected = this.removeItemsFromArray(this.selectedExtensionsModules, ['TBISYSCS4'])
        modifiedSelected.push('TBPSYSCS4')
        return modifiedSelected
      }
      return this.selectedExtensionsModules
    },
    additionalApps () {
      return this.selectedApplications.filter((el) => {
        return !this.preselectedApplications.includes(el)
      })
    },
    additionalAccessories () {
      return this.selectedAccessories.filter((el) => {
        return !this.preselectedAccessories.includes(el)
      })
    },
    alwaysSelectedExtMods () {
      return ['MTABSYSW']
    }
  },
  methods: {
    extNiceName (id) {
      const obj = this.extModByID(id)
      return obj.name
    },
    appNiceName (id) {
      const obj = this.applicationByID(id)
      return obj.name
    },
    accNiceName (id) {
      const obj = this.accessoryByID(id)
      return obj.name
    },
    includesList (id) {
      const obj = this.extModByID(id)
      return obj.includesList
    },
    imgPathExtMod: path => require('@/assets/extModules/interactive/' + path),
    imgPathApps: path => require('@/assets/apps/visual/' + path),
    imgPathAccs: path => require('@/assets/accessories/visual/' + path)
  }
}
</script>

<style lang="scss" scoped>
$imgw: 70px;

.m-mw-area {
  max-width: 190px;

  &.m-mw-area-lg {
    max-width: 330px;
  }
}

.m-block {
  border: 1px solid var(--v-secondary-lighten5);
  border-radius: 1rem;
  margin-bottom: 1rem;

  .title {
    padding-bottom: 0;
  }
}

ul {
  vertical-align: top;
}

.m-icon-wrap {
  max-width: $imgw;
  vertical-align: top;
  margin-bottom: 3px;

  .caption {
    line-height: 1.2;
    margin-top: 3px;
  }
}

.img {
  border: 2px solid var(--v-secondary-lighten5);
  border-radius: 50%;
  max-width: $imgw;
}
</style>
