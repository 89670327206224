<template>
  <v-dialog
    v-model="showDialog"
    content-class="elevation-0"
    class="elevated-0"
    width="500"
    max-width="800"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container>
      <v-btn block text depressed color="white" @click="closeDialogs">
        <span class="text-decoration-underline">Close</span>
      </v-btn>
      <v-form class="flex-grow-1 white rounded pt-5 px-5 mb-5" ref="inquiryForm">
        <label>Type in your email address:</label>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
        <Disclaimer />
      </v-form>
      <v-btn @click="submitForm" color="accent" block x-large rounded depressed class="subtitle-1" :disabled="sendDisabled">SEND INQUIRY</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Disclaimer from '@/components/Disclaimer.vue'

export default {
  name: 'SendInquiryDialog',
  props: {
    show: Boolean
  },
  components: {
    Disclaimer
  },
  computed: {
    ...mapGetters([
      'configURL'
    ]),
    showDialog () {
      return this.show
    },
    dynamicDataObj () {
      return {
        action: 'configuratorSendEmail',
        email: this.email,
        url: this.configURL,
        configuration: JSON.stringify(this.$store.state.selected)
      }
    }
  },
  data: function () {
    return {
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => this.emailRegex.test(v) || 'E-mail must be valid'
      ],
      sendDisabled: false
    }
  },
  methods: {
    closeDialogs () {
      this.$store.commit('CLOSE_ALL_DIALOGS')
    },
    validateForm () {
      return this.$refs.inquiryForm.validate()
    },
    submitForm () {
      if (this.saEvent) {
        this.saEvent('SendInquiry')
      }
      this.trackEvent('SendInquiry', 'ButtonClick')
      if (!this.validateForm()) {
        if (this.saEvent) {
          this.saEvent('SendInquiryFailedValidation')
        }
        this.trackEvent('SendInquiry', 'Forms', 'failed validation')
        return
      }
      this.sendDisabled = true

      return new Promise((resolve, reject) => {
        this.axios({
          url: this.$store.getters.APIURL,
          data: this.dynamicDataObj,
          method: 'POST'
        })
          .then(resp => {
            console.warn('SERVER RESPONSE: ', resp)
            resolve(resp)
            this.sendDisabled = false
            if (!resp.data.status) {
              this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: resp.data.message })
              if (this.saEvent) {
                this.saEvent('SendInquiryForms', { formMessage: resp.data.message })
              }
              this.trackEvent('SendInquiry', 'Forms', resp.data.message)
            } else {
              if (this.saEvent) {
                this.saEvent('SendInquiryOK')
              }
              this.trackEvent('SendInquiry', 'Forms', 'OK')
              this.$store.dispatch('SHOW_DIALOG_THANK_YOU')
            }
          })
          .catch(err => {
            console.warn('sendEmail error: ', err)
            if (this.saEvent) {
              this.saEvent('SendInquiryERROR')
            }
            this.trackEvent('SendInquiry', 'Forms', err.message)
            this.sendDisabled = false
            this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
          })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
