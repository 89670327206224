<template>
  <div class="mt-5 ctas-wrap">
    <v-row class="mb-5">
      <v-col cols="12" sm="6" class="d-flex flex-column justify-space-between">
        <div>{{ $t('inquiryOffer') }}</div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn data-cy-send-inquiry-btn color="accent" block x-large rounded depressed class="subtitle-1 flex-grow-0" @click="sendInquiryClick">{{ $t('inquiryActions.sendInquiry') }}</v-btn>
        <SendInquiryDialog :show="isSendInquiryDialogVisible" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SendInquiryDialog from '@/components/dialogs/SendInquiryDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InquiryActions',
  props: {},
  components: {
    SendInquiryDialog
  },
  computed: {
    ...mapGetters([
      'isSendInquiryDialogVisible',
      'FORMURL',
      'configURL',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'selectedLayout'
    ])
  },
  methods: {
    sendInquiryClick () {
      if (this.saEvent) {
        this.saEvent('send-inquiry-CTA')
      }
      const extMods = this.selectedExtensionsModules.toString()
      const applications = this.selectedApplications.toString()
      const accessories = this.selectedAccessories.toString()
      const layouts = this.selectedLayout.toString()
      const languageForm = this.$t('inquiryForm')
      this.trackEvent('SendInquiryCTA', 'ButtonClick')
      window.open(this.FORMURL + '?url=' + encodeURIComponent(this.configURL) + '&extMods=' + extMods + '&apps=' + applications + '&accs=' + accessories + '&layouts=' + layouts + '&form=' + languageForm, '_blank')
      // this.$store.commit('SHOW_SEND_INQUIRY_DIALOG')
    }
  }
}

</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.price-div {
  display: inline-block;
  position: relative;

  .info-btn {
    top: -1.5rem;
    right: -2rem;
  }
}

.divider-avatar {
  position: relative;

  .m-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

</style>

<style lang="scss">
.ctas-wrap {
  .v-btn__content {
    font-size: 1.2rem;
  }
}
</style>
