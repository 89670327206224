<template>
  <div class="visual-block-step step-3-visual-block pa-6 mt-8 mb-12" :class="{ 'active-step' : isActiveStep }" ref="Accessories">

    <div class="pl-1 mb-2 title text-uppercase">{{ $t('included') }}</div>
    <div class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
        <div
          v-for="(selectedKey, k) in preselectedAccessoriesModified"
          :key="selectedKey + k"
          class="d-inline-block img-wrap ma-3 app-item"
          transition="slide-y-transition"
        >
          <v-img :src="imgPath(selectedKey)" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selectedKey" :title="selectedKey" contain>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </transition-group>
    </div>

    <div class="pl-1 mb-2 mt-4 title text-uppercase" v-if="additionalAccessories.length > 0">{{ $t('additional') }}</div>
    <div class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
        <div
          v-for="(selectedKey, k) in additionalAccessories"
          :key="selectedKey + k"
          class="d-inline-block img-wrap ma-3 app-item"
          transition="slide-y-transition"
        >
          <v-img :src="imgPath(selectedKey)" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selectedKey" :title="selectedKey" contain>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccessoriesVisual',
  props: {
    stepIndex: Number,
    systemData: Object,
    isActiveStep: Boolean
  },
  computed: {
    lazyImgSrc () {
      return require('@/assets/placeholder.jpg')
    },
    ...mapGetters({
      selected: 'selectedAccessories'
    }),
    ...mapGetters([
      'steps',
      'activeStep',
      'preselectedAccessories'
    ]),
    preselectedAccessoriesModified () {
      if (this.preselectedAccessories.includes('CUFFMABI') && this.preselectedAccessories.includes('CUFFMBP')) {
        // remove CUFFMBP since cuffs already with ABI
        const modifiedSelected = this.removeItemsFromArray(this.preselectedAccessories, ['CUFFMBP'])
        return modifiedSelected
      }
      return this.preselectedAccessories
    },
    additionalAccessories () {
      return this.selected.filter((el) => {
        return !this.preselectedAccessories.includes(el)
      })
    },
    componentName () {
      return this.steps[this.stepIndex].componentName
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  },
  methods: {
    imgPath (id) {
      const obj = this.getDataObj(id)
      if (typeof obj === 'undefined') {
        console.warn('MISSING accessory PHOTO for ' + id, ' : ', obj)
        return ''
      }
      if (obj.img) {
        // return require('@/assets/placeholder.jpg')
        return require('@/assets/accessories/visual/' + obj.img)
      }
      return require('@/assets/placeholder.jpg')
    },
    getDataObj (id = '') {
      return this.$store.getters.accessoryByID(id)
    }
  }
}
</script>

<style lang="scss" scoped>
$transitionSpeed: 1s;

.tr-group-wrap {
  position: relative;
  overflow: hidden;
}

.app-item {
  transition: all 1s;
}
.apps-wrap-enter, .apps-wrap-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.apps-wrap-leave-active {
  position: absolute;
}

.img-wrap {
  position: relative;

  .img {
    max-width: 100px;
    border: 2px solid var(--v-secondary-lighten5);
    border-radius: 50%;
    // so non square images are same width/height too
    height: 0;
    padding-bottom: 100%;
  }
}
</style>
