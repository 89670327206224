import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Root from './Root.vue'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Terms from '../views/Terms.vue'
import ThankYou from '../views/ThankYou.vue'
import a404 from '../views/a404.vue'
import i18n from '../i18n.js'
import { SUPPORTED_LOCALES } from '../locales/locales.js'
import en from '../locales/en.json'
import de from '../locales/de.json'
import fr from '../locales/fr.json'
import es from '../locales/es.json'
import it from '../locales/it.json'
import sl from '../locales/sl.json'

Vue.use(VueRouter)

function getLocaleRegex () {
  let reg = ''
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: 'start',
    name: 'Start',
    component: Start
  },
  {
    path: 'code/:code',
    name: 'Code',
    component: Home
  },
  {
    path: 'terms-conditions',
    name: 'Terms & Conditions',
    component: Terms
  },
  {
    path: 'thank-you',
    name: 'Thank You',
    component: ThankYou
  },
  {
    // will match everything
    path: '*',
    name: '404',
    component: a404
  }
]

const translations = {
  en,
  de,
  fr,
  es,
  it,
  sl
}

// Returns locale configuration
function getLocale (locale = 'en') {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale)
}

const router = new VueRouter({
  mode: 'history',
  routes: [{
    path: `/:locale${getLocaleRegex()}?`,
    component: Root,
    beforeEnter (to, from, next) {
      const locale = getLocale(to.params.locale)
      // console.log('BEFORE ENTER: ', locale, ' To params locale: ', to.params.locale)
      store.commit('SET_LOCALE', locale)
      store.commit('SET_INTERFACE_TEXTS', locale)
      store.dispatch('INIT_DATA')
      i18n.setLocaleMessage(locale.code, translations[locale.code])
      i18n.locale = locale.code
      next(vm => {
        vm.previousRoute = from
      })
    },
    children: routes
  }]
})

export default router
