<template>
  <div>
    <sectionTitle>
      {{ $t('accessories.title') }}
      <template v-slot:subtitle>
        {{ $t('accessories.subtitle') }}
      </template>
    </sectionTitle>
    <div class="pb-12">
      <v-row>
        <v-col
          class="item d-flex child-flex"
          :class="itemClass(acc.id)"
          cols="12"
          sm="6"
          v-for="(acc, k) in filteredAccessories"
          :key="k">
          <div>
            {{ acc.name }}<br />
            <span class="font-weight-light">{{ acc.subtext }}</span>
          </div>
          <v-switch
            @change="toggleAccessory(acc.id)"
            v-model="selected"
            class="px-4"
            multiple
            inset
            data-cy-acc-item
            color="accent"
            hide-details
            :value="acc.id"
            :disabled="isDisabled(acc.id)">
          </v-switch>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'
import { mapGetters } from 'vuex'
import accessoriesData from '@/data/en/accessories.json'

export default {
  name: 'Accessories',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    sectionTitle
  },
  created () {
    this.toggleAccessory = this.debounce(this.toggleAccessory)
  },
  computed: {
    ...mapGetters([
      'steps',
      'allAccessoriesData',
      'allowedAccessories',
      'preselectedAccessories'
    ]),
    filteredAccessories () {
      /* return this.allAccessoriesData.filter(acc => {
        return !this.isDisabled(acc.id)
      }) */
      if (this.allAccessoriesData.length > 0) {
        return this.allAccessoriesData.filter(acc => {
          return !this.isDisabled(acc.id)
        })
      } else {
        return accessoriesData.filter(acc => {
          return !this.isDisabled(acc.id)
        })
      }
    },
    selected: {
      get () {
        return this.$store.getters.selectedAccessories
      },
      set (newSelected) {
      }
    }
  },
  methods: {
    toggleAccessory (accID = -1) {
      if (this.saEvent) {
        this.saEvent('accessorie-select', { id: accID })
      }
      this.$store.dispatch('TOGGLE_ACCESSORY', accID)
    },
    isDisabled (id) {
      return this.isPreselected(id) || !this.allowedAccessories.includes(id)
    },
    isPreselected (id) {
      return this.preselectedAccessories.includes(id)
    },
    itemClass (id) {
      let classes = ''
      if (!this.allowedAccessories.includes(id) && !this.isPreselected(id)) {
        classes += 'disabled'
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  &.disabled {
    opacity: 0.4;
  }
}

.v-input--switch.v-input--is-dirty.v-input--is-disabled {
  opacity: .3;
}
</style>
